<template>
  <div id="app">

    <div class="bg_green">
      <div class="white_box3">
        <div class="white_box3_in">
          <!-- 用户 -->
          <div class="user pd_3p6_0 clear" v-if="customerUser.businessCustomerUser">
            <div class="user_avatar larger" :style="{backgroundImage:`url(${img2})`}"></div>
            <div class="user_msg">
              <span>{{ customerUser.businessCustomerUser.name }}</span>
              <label>{{
                  customerUser.businessCustomerUser.business.name
                }}，{{ customerUser.businessCustomerUser.structure.name }}</label>
            </div>
          </div>
          <!-- 余额 -->
          <div v-if="showAccountBalance && showAccountBalance.setValue === '1'">
            <div :class="{hide:showBalance==true}" class="balance" style="display: flex; margin-bottom: 8px;">
              <button class="button_green mrg_0_auto" @click="getBalance">查看账户余额</button>
              <button class="button_green mrg_0_auto"
                      v-if="currentCustomerUser.businessId ==='017b972ae06b003b2c96c1f37b970f88' || currentCustomerUser.businessId ==='017b9122d74000742c96c1f47b90d56f' "
                      @click="getPrepaidCardBalance">查看红包余额
              </button>
            </div>
            <div class="balance" v-show="showBalance">
              <div class="balance_t">账户余额</div>
              <div class="balance_b">
                <ul class="clear">
                  <li>{{
                      formatAmount(totalBalance)
                    }}
                  </li>
                  <li>
                    <router-link
                        :to="'transaction_log?businessCustomerUserId=' + customerUser.businessCustomerUserId">
                      交易记录 <span class="iconfont">&#xe615;</span>
                    </router-link>
                  </li>
                </ul>
              </div>
            </div>
            <div class="balance" v-show="showCardBalance">
              <div class="balance_t">红包余额</div>
              <div class="balance_b">
                <ul class="clear">
                  <li>{{ cardBalance }}</li>
                  <li>
                    <router-link
                        :to="'transaction_log?businessCustomerUserId=' + customerUser.businessCustomerUserId">
                      交易记录 <span class="iconfont">&#xe615;</span>
                    </router-link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="white_box3 mgt_3vw">
      <div class="white_box3_in">
        <!-- 副标题 -->
        <div class="sub_title">当前消费用户</div>
        <!-- 用户 -->
        <div class="user under_line pd_3p6_0 clear"
             @click="jumpCustomerUseAccount(currentCustomerUser)"
             v-if="currentCustomerUser.businessCustomerUser">
          <div class="user_avatar" :style="{backgroundImage:`url(${img})`}"></div>
          <div class="user_msg">
            <span>{{ currentCustomerUser.businessCustomerUser.name }}</span>
            <label>{{
                currentCustomerUser.businessCustomerUser.business.name
              }}，{{ currentCustomerUser.businessCustomerUser.structure.name }}</label>
          </div>
        </div>

        <!-- 副标题 -->
        <div class="sub_title mgt_4vw">其他消费用户</div>

        <!-- 用户 -->
        <div class="user under_line pd_3p6_0 clear" v-for="(option, index) in customerUserOptions" :key="index"
             @click="jumpCustomerUseAccount(option)">
          <div class="user_avatar" :style="{backgroundImage:`url(${img})`}"></div>
          <div class="user_msg">
            <span v-if="option.businessCustomerUser">{{ option.businessCustomerUser.name }}</span>
            <label
                v-if="option.businessCustomerUser">{{
                option.businessCustomerUser.business.name
              }}，{{ option.businessCustomerUser.structure.name }}</label>
          </div>
        </div>

        <!-- 按钮 -->
        <div class="two_btn">
          <ul class="clear">
            <li>
              <button class="button_green" @click="jumpCashout">提现</button>
            </li>
<!--            <li>-->
<!--              <button class="button_green" @click="jumpApply">报名</button>-->
<!--            </li>-->
            <li>
              <button class="button_orange" @click="jumpRecharge">充值</button>
            </li>
          </ul>
        </div>
      </div>

    </div>

    <div class="loading_all" v-if="loading">
      <van-loading type="spinner" color="#ffffff" vertical>加载中...</van-loading>
    </div>

  </div>
</template>

<style scoped>

</style>

<script>
import Vue from 'vue'
import {ACCESS_CustomerUser} from '@/store/mutation-types'
import {post} from '@/utils/http'
import Notify from "vant/es/notify";
import {formatAmount} from "../utils/util";
import {Dialog, Toast} from "vant";

// 注意，是查看消费者的钱包，而不是客户的钱包
export default {
  data() {
    return {
      img: require('../assets/images/avatar.png'),
      img2: require('../assets/images/avatar2.png'),
      showBalance: false,

      loading: false,
      customerUserOptions: [],
      customerUser: {},
      currentCustomerUser: {},
      totalBalance: 0,
      customerAccount: {},
      showAccountBalance: {},

      cardBalance: 0,
      showCardBalance: false,
      paymentInfo: null
    };
  },
  destroyed() {
    window.removeEventListener('popstate', this.goBack, false);
  },
  mounted() {
    if (window.history && window.history.pushState) {
      history.pushState(null, null, document.URL);
      window.addEventListener('popstate', this.goBack, false);
    }

    this.currentCustomerUser = Vue.ls.get(ACCESS_CustomerUser)
    this.switchUser(this.currentCustomerUser);

    this.loading = true
    post('api/customer/user/v2/search', {}).then(res => {
      res.data.rows.forEach(item => {
        if (item.id != this.currentCustomerUser.id) {
          this.customerUserOptions.push(item)
        }
      });
      this.loading = false
    }).catch(() => this.loading = false)

    post('api/customer/account/v2/find', {}, true).then(res => {
      this.customerAccount = res.data
    }).catch(() => {
    });
  },
  methods: {
    goBack() {
      this.$router.push('/index');
      window.history.pushState('forward', null, '');
      window.history.forward(1);
    },
    // 注意，这个只是当前页面切换，并不是切换当前用户
    switchUser(data) {
      if (!data) return;
      this.customerUser = data;
      this.showBalance = false;
      this.loadBusinessPaymentInfo();
    },
    jumpCashout() {
      if (!this.paymentInfo) {
        return;
      }
      if (this.paymentInfo.needPassword === '0') {
        this.$router.push({path: '/cashout'});
        return;
      }

      this.loading = true;
      post('api/customer/account/v2/:businessCustomerUserId/find'.replace(':businessCustomerUserId', this.customerUser.businessCustomerUserId), {}, true).then(res => {
        this.customerAccount = res.data

        if (!this.customerAccount || !this.customerAccount.id) {
          this.$router.push({path: '/AccountAgreement'});
          return;
        }
        // 根据不同平台进行判断
        if (this.customerAccount.auditStatus == 'YL' && this.paymentInfo.code === 'GF') {
          this.$router.push({path: '/AccountAgreement'});
          return;
        }

        if (!this.checkAccountState()) {
          return;
        }
        this.$router.push({path: '/cashout'})

        this.loading = false;
      }).catch(() => {
        this.loading = false;
      });

    },
    jumpApply() {
      this.$router.push({path: '/recharge/applyRechargeList'});
    },
    jumpRecharge() {
      if (!this.paymentInfo) {
        return;
      }
      // TODO:临时弹窗
      // let nowDate = new Date();
      // if (this.paymentInfo.code === 'GF' && nowDate.getTime() > 1682956800000 && nowDate.getTime() < 1683129600000) {
      //   Dialog.alert({
      //     title: '温馨提示',
      //     message: '您好！为提供更优质的服务，广发银行将进行系统升级，时间为2023 年 5 月 2 日 00:00 至 5 月 3 日 24:00，届时一天膳事平台中缴费相关功能板块将暂时无法使用，2023 年 5 月 4 日 0 点起恢复正常。',
      //     confirmButtonText: '我知道了',
      //     confirmButtonColor: '#34AC40'
      //   }).then(() => {
      //
      //   });
      //   return;
      // }

      if (this.paymentInfo.needPassword === '0') {
        this.$router.push({path: '/recharge'});
        return;
      }

      this.loading = true;
      post('api/customer/account/v2/:businessCustomerUserId/find'.replace(':businessCustomerUserId', this.customerUser.businessCustomerUserId), {}, true).then(res => {
        this.customerAccount = res.data

        if (this.paymentInfo.code === 'GF') {
          if (!this.customerAccount.id) {
            this.$router.push({path: '/AccountAgreement'});
            return;
          } else if (!this.checkAccountState()) {
            return;
          }
        }

        this.$router.push({path: '/recharge'})

        this.loading = false;
      }).catch(() => {
        this.loading = false;
      });

    },
    checkAccountState() {
      if (this.customerAccount.auditStatus === '04') {
        // 二审失败
        this.$router.push({
          path: '/AccountCreateFeedback',
          query: {type: 'UPGRADE_FAIL', msg: this.customerAccount.auditRemark}
        });
        return false;

      } else if (this.customerAccount.auditStatus === '05') {
        // 等待影相再审核
        this.$router.push({path: '/AccountCreateFeedback', query: {type: 'ID_CARD_REVIEWING'}});
        return false;

      }
      return true;
    },
    jumpCustomerUseAccount(data) {
      this.loading = true;
      // 获取消费者的渠道信息
      post("api/business/v2/{businessId}/business/customer/user/paymentInfoQuery".replace('{businessId}', data.businessId))
          .then(result => {
            const currentPaymentInfo = result.data;
            // 根据不同渠道信息进行跳转
            post('api/customer/account/v2/:businessCustomerUserId/find'
                .replace(':businessCustomerUserId', data.businessCustomerUserId), {}, true).then(res => {
              const currentCustomerAccount = res.data;

              if (currentPaymentInfo.code === 'GF') {
                if (!currentCustomerAccount.id) {
                  this.$router.push({path: '/bankSwitch', query: {backUrl: 'customer_user_account'}})
                  return;
                }
                if (currentCustomerAccount.auditStatus === '04') {
                  // 二审失败
                  this.$router.push({
                    path: '/AccountCreateFeedback',
                    query: {type: 'UPGRADE_FAIL', msg: currentCustomerAccount.auditRemark}
                  });
                  return;

                } else if (currentCustomerAccount.auditStatus === '05') {
                  // 等待影相再审核
                  this.$router.push({path: '/AccountCreateFeedback', query: {type: 'ID_CARD_REVIEWING'}});
                  return;
                }

              }

              this.$router.push({
                path: '/business_customer_user_free_amount',
                query: {businessCustomerUserId: data.businessCustomerUserId}
              })

              this.loading = false;

            }).catch(error => {
              Toast(error.message);
              this.loading = false;
            });

          })
          .catch(error => {
            Toast(error.message);
            this.loading = false;
          })

    },
    jumpOldSystem() {
      window.location.href = 'https://wx.xzb360.com/xzb/home';
    },
    getPrepaidCardBalance() {
      this.loading = true
      post('api//business/v2/' + this.customerUser.businessId + '/business/customer/user/' + this.customerUser.businessCustomerUserId + '/user/pocket/search', {
        filters: [{name: 'type', op: '=', value: 'PREPAID_CARD'}]
      }).then(res => {
        if (res && res.data && res.data.rows && res.data.rows.length > 0) {
          this.cardBalance = res.data.rows[0].balance
        } else {
          this.cardBalance = 0;
        }

        this.showCardBalance = true;
        this.showBalance = false;
        this.loading = false
      }).catch(error => {
        Notify({type: 'warning', message: error.message});
        this.loading = false
      })
    },
    getBalance() {
      // if (!this.customerAccount || !this.customerAccount.id) {
      //   this.$router.push({path: '/bankSwitch', query: {backUrl: 'customer_user_account'}})
      //   return;
      // }
      //
      // this.loading = true
      // post('api/guangfa/v2/queryAccountBalance', {}).then(res => {
      //   this.totalBanlance = res.data.eAccAmt
      //   this.showBalance = true
      //   this.showCardBalance = false;
      //   this.loading = false
      // }).catch(error => {
      //   Notify({type: 'warning', message: error.message});
      //   this.loading = false
      // })
      this.loading = true
      post('api/business/v2/{businessId}/business/customer/user/{businessCustomerUserId}/user/pocket/search'
          .replace('{businessId}', this.customerUser.businessId)
          .replace('{businessCustomerUserId}', this.customerUser.businessCustomerUserId), {
        filters: [],
        page: 0,
        size: 100
      }).then(res => {
        this.totalBalance = 0;
        if (res && res.data && res.data.rows && res.data.rows.length > 0) {
          for (let i = 0; i < res.data.rows.length; i++) {
            let userPocket = res.data.rows[i];
            this.totalBalance += userPocket.balance;
          }
        }
        if (this.totalBalance < 0) {
          Notify({type: 'warning', message: '余额显示异常，请联系客服处理，谢谢'});
        }

        this.showCardBalance = false;
        this.showBalance = true;
        this.loading = false
      }).catch(error => {
        Notify({type: 'warning', message: error.message});
        this.loading = false
      })
    },
    loadBusinessPaymentInfo() {
      post('api/business/v2/{businessId}/setting/search'.replace('{businessId}', this.customerUser.businessId), {
        size: 500
      })
          .then(res => {
            this.showAccountBalance = res.data.rows.find(item => item.setKey === 'SHOW_ACCOUNT_BALANCE'); // 仅解冻自由充值金额
            if (!this.showAccountBalance) {
              this.showAccountBalance = {setValue: '1'};// 默认为是
            }
          })
          .catch(() => {
          });

      post("api/business/v2/{businessId}/business/customer/user/paymentInfoQuery".replace('{businessId}', this.customerUser.businessId))
          .then(result => {
            this.paymentInfo = result.data;
          })
          .catch(error => {
            console.log(error.message);
          })
    },
    formatAmount(amount) {
      if (amount) {
        return formatAmount(amount);
      } else {
        return "0.00";
      }
    },
  }
};
</script>
